import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { Loader } from "components-care";
import React, { Suspense, useCallback, useState } from "react";
import PortalHeader from "./PortalHeader";
import Routes from "./Routes";
import SamedisMenu from "./SamedisMenu";
import useIsMobile from "../utils/useIsMobile";
export const useSamedisPortalStyles = makeStyles()((theme) => ({
    html: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    body: {
        flexGrow: 1,
        overflow: "auto",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
    },
    header: {
        position: "sticky",
        padding: theme.spacing(0, 3),
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up("sm")]: {
            boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.2)",
        },
    },
}));
const SamedisPortal = () => {
    const { classes } = useSamedisPortalStyles();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down("md"));
    const mobile = useIsMobile();
    const renderContent = () => (_jsx(Suspense, { fallback: _jsx(Loader, {}), children: _jsx(Routes, {}) }));
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const openMobileMenu = useCallback((evt) => {
        setMenuAnchorEl(evt.currentTarget);
    }, []);
    const closeMenu = useCallback(() => {
        setMenuAnchorEl(null);
    }, []);
    return (_jsxs("div", { className: classes.html, children: [_jsxs(Grid, { container: true, direction: "column", id: "SamedisHeader", className: classes.header, children: [_jsxs(Grid, { item: true, container: true, wrap: "nowrap", alignContent: "center", alignItems: "center", spacing: 2, children: [isMd && (_jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: openMobileMenu, size: mobile ? "small" : "medium", children: _jsx(MenuIcon, {}) }) })), _jsx(Grid, { item: true, xs: true, children: _jsx(PortalHeader, {}) })] }), !isMd ? (_jsx(SamedisMenu, { mobile: false, closeMenu: closeMenu })) : (_jsx(SamedisMenu, { mobile: true, mobileAnchorEl: menuAnchorEl, closeMenu: closeMenu }))] }), _jsx("div", { className: classes.body, children: renderContent() })] }));
};
export default React.memo(SamedisPortal);
