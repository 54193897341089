import { jsx as _jsx } from "react/jsx-runtime";
import { Framework } from "components-care/dist/framework";
import { getTheme } from "./theme";
import ErrorBoundary from "./components/ErrorBoundary";
import SamedisPortal from "./components/SamedisPortal";
const mobileScalingFixProps = {
    minWidth: 320,
};
function App() {
    return (_jsx(Framework, { defaultTheme: getTheme, disableMuiPickerUtils: true, disableUnsafeToLeave: true, mobileScalingFixProps: mobileScalingFixProps, children: _jsx(ErrorBoundary, { children: _jsx(SamedisPortal, {}) }) }));
}
export default App;
