import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "@fontsource/roboto";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto-condensed";
import "@fontsource/roboto-condensed/500.css";
import "@fontsource/roboto-condensed/700.css";
const domRoot = document.getElementById("root");
const root = createRoot(domRoot);
root.render(_jsx(React.StrictMode, { children: _jsx(App, {}) }));
