import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from "react";
import SourceRoutes from "../assets/routes.json";
import { useRoutedMenuLogic, } from "components-care/dist/non-standalone/Portal/Menu";
import { MenuContext } from "components-care/dist/standalone/Portal/Menu/MenuItem";
import SamedisMenuGroup from "./SamedisMenuGroup";
import { Icon } from "@mui/material";
import { getLocalizedLabel } from "./Routes";
import useCCTranslations from "components-care/dist/utils/useCCTranslations";
import { makeStyles } from "tss-react/mui";
import { useAsyncMemo } from "components-care";
const useMenuIconStyles = makeStyles()({
    icon: {
        textAlign: "center",
    },
    img: {
        height: "100%",
        width: "100%",
        objectFit: "contain",
        filter: "invert(100%)",
    },
});
const MenuIcon = (topic) => (props) => {
    // eslint-disable-next-line
    const src = useAsyncMemo(() => import(`../assets/route-icons/${topic}.svg`), []);
    // eslint-disable-next-line
    const { classes } = useMenuIconStyles();
    return (_jsx(Icon, { ...props, className: classes.icon, children: _jsx("img", { alt: "", src: src?.default, className: classes.img }) }));
};
const SamedisMenu = (props) => {
    const { mobile, mobileAnchorEl, closeMenu } = props;
    const { i18n } = useCCTranslations();
    const menuRawDefinition = useMemo(() => Object.entries(SourceRoutes).map(([topic, def]) => ({
        title: getLocalizedLabel(i18n.language, def.label),
        icon: def.icon // hacky, but should be fine because useMemo
            ? MenuIcon(topic)
            : undefined,
        shouldRender: true,
        children: Object.entries(def.functions).map(([fn, fnDef]) => ({
            title: getLocalizedLabel(i18n.language, fnDef.label),
            shouldRender: true,
            route: `/${topic}/${fn}`,
            onClick: closeMenu,
        })),
        route: undefined,
    })), [i18n.language, closeMenu]);
    const { definition: menuDefinition, controlledState } = useRoutedMenuLogic({
        definition: menuRawDefinition,
    });
    return (_jsx(MenuContext.Provider, { value: controlledState, children: _jsx(SamedisMenuGroup, { vertical: mobile, anchorEl: mobileAnchorEl, itemDefs: menuDefinition, onClose: closeMenu, menuItemId: null }) }));
};
export default React.memo(SamedisMenu);
