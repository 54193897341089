import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Grid, Hidden, useMediaQuery, useTheme, IconButton, } from "@mui/material";
import SamedisLogoApp from "../assets/img/Samedis_RGB.svg";
import SamedisLogoSmall from "../assets/img/Samedis_small.svg";
import { Translate as TranslateIcon } from "@mui/icons-material";
import { combineClassNames, LocaleSelectorDialog, useDialogContext, } from "components-care";
import supportedLanguages from "../assets/languages.json";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme) => ({
    header: {
        [theme.breakpoints.up("xs")]: {
            padding: theme.spacing(2, 0, 1, 0),
        },
    },
    logo: {
        width: "auto",
        height: 24,
    },
    logoMobile: {
        cursor: "pointer",
    },
}));
const PortalHeader = () => {
    const { classes } = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    const [pushDialog] = useDialogContext();
    const openLanguageDialog = useCallback(() => {
        pushDialog(_jsx(LocaleSelectorDialog, { supportedLocales: supportedLanguages }));
    }, [pushDialog]);
    return (_jsxs(Grid, { container: true, justifyContent: "flex-start", alignItems: "center", spacing: isXs ? 1 : 2, wrap: "nowrap", className: classes.header, children: [" ", _jsx(Hidden, { implementation: "js", mdDown: true, children: _jsx(Grid, { item: true, children: _jsx("img", { src: SamedisLogoApp, className: classes.logo, alt: "Samedis.care" }) }) }), _jsx(Hidden, { implementation: "js", mdUp: true, children: _jsx(Grid, { item: true, children: _jsx("img", { src: SamedisLogoSmall, className: combineClassNames([
                            classes.logo,
                            isXs && classes.logoMobile,
                        ]), alt: "Samedis.care" }) }) }), _jsx(Grid, { item: true, xs: true }), _jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: openLanguageDialog, children: _jsx(TranslateIcon, {}) }) })] }));
};
export default React.memo(PortalHeader);
