import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import SourceRoutes from "../assets/routes.json";
import useCCTranslations from "components-care/dist/utils/useCCTranslations";
import { Routes as RouterRoutes, Route, useNavigate } from "components-care";
import { makeStyles } from "tss-react/mui";
export const getLocalizedLabel = (locale, labels) => {
    if (locale in labels)
        return labels[locale];
    const localeShort = locale.split("-")[0];
    if (localeShort in labels)
        return labels[localeShort];
    return labels["en"]; // should never happen
};
export const useRouteDefinitions = () => {
    const { i18n } = useCCTranslations();
    const definition = useMemo(() => Object.entries(SourceRoutes)
        .map(([topic, def]) => Object.entries(def.functions).map(([fn, fnDef]) => ({
        breadcrumb: " > " +
            getLocalizedLabel(i18n.language, def.label) +
            " > " +
            getLocalizedLabel(i18n.language, fnDef.label),
        file: getLocalizedLabel(i18n.language, fnDef.documents),
        route: `/${topic}/${fn}`,
    })))
        .flat(), [i18n.language]);
    return definition;
};
const useStyles = makeStyles()({
    iframe: {
        border: "none",
        width: "100%",
        height: "100%",
    },
});
const Navigate = (props) => {
    const { to } = props;
    const navigate = useNavigate();
    // redirect does not work on first render, so delay it to second render
    const [goAway, setGoAway] = useState(false);
    useEffect(() => {
        setGoAway(true);
    }, []);
    useEffect(() => {
        if (!goAway)
            return;
        navigate(to, { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goAway]);
    return _jsx(_Fragment, {});
};
const Routes = () => {
    const { classes } = useStyles();
    const definition = useRouteDefinitions();
    return (_jsx(RouterRoutes, { children: definition
            .map((def) => (_jsx(Route, { path: def.route, element: _jsx("iframe", { title: def.breadcrumb, className: classes.iframe, src: def.file }) }, def.route)))
            .concat([
            _jsx(Route, { path: "/", element: _jsx(Navigate, { to: definition[0].route }) }, "root"),
        ]) }));
};
export default React.memo(Routes);
